// import logo from './assets/title/HD_Logo_offset1.png';
import logo from './assets/title/HV_V3_Banner_3000x900.jpeg';
import ihstamp from './assets/IH/InnerHeroBlackStampBlack.jpg';
import applab from './assets/title/app-lab.jpg';

import './App.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import SS1 from './assets/SSs/HDSS1.jpeg';
import SS2 from './assets/SSs/HDSS2.jpeg';
import SSDeer from './assets/SSs/SSDeer1.jpeg';
import SS3 from './assets/SSs/HDSS3.jpeg';
import SS4 from './assets/SSs/HDSS4.jpeg';
import SS5 from './assets/SSs/HDSS5.jpeg';
import SS6 from './assets/SSs/HDSS6.jpeg';

function App() {
  return (
    <div className="App">

      <div className="App-title">
        <img src={ihstamp} className="ih-logo" alt="ihstamp" />
      </div>

      {/* <div className="App-divider-orange"></div> */}

      <div className="App-banner">
        <img src={logo} className="App-logo" alt="logo" />
      </div>

      <div className="App-download">
        <a href="https://www.oculus.com/experiences/quest/4638602122894234/?utm_source=website" target="_blank" rel="noopener noreferrer">
          <button className="App-download-button">
            <img src={applab} className="Applab-logo" alt="logo" />
            Download Now!
          </button>
        </a>
      </div>

      <div className="App-body">
      Experience the cutest hunting adventure in VR with HuntyVerse!
        {/* <div className='App-body-large-font'>
          <b><u>Join while it's only $4.99!</u></b>
        </div> */}
        
        <div className='App-body-small-font'>
          Now FREE on Meta Quest!
        </div>
      </div>

      <div className="App-Video">
        <u>Video Trailer</u>

        <iframe src='https://www.youtube.com/embed/FxBkaMyW7_s?showinfo=0&showsearch=0&rel=1&iv_load_policy=3&cc_load_policy=1&autoplay=1&loop=1&fs=0'
            frameborder='0'
            allow='autoPlay; encrypted-media'
            title='video'
        />

        <br/>
        <u>Screenshots</u>
      </div>

        
      <div className="App-divider-orange"></div>
      
      <Carousel autoPlay autoFocus infiniteLoop showStatus={false} showArrows={false} showThumbs={false}>
          <div>
              <img src={SS1} />
          </div>
          <div>
              <img src={SS2} />
          </div>
          <div>
              <img src={SSDeer} />
          </div>
          <div>
              <img src={SS3} />
          </div>
          <div>
              <img src={SS4} />
          </div>
          <div>
              <img src={SS5} />
          </div>
          <div>
              <img src={SS6} />
          </div>
      </Carousel>
      
      <div className="App-divider-black"></div>
      

      

      <div className="App-body2">
        This bubbly arcade shooter will put your skills up against your friends and the world’s best in 3 overly addictive game modes.
          <br/>
        <b><u>Streak:</u></b>
          Hit the most duckz you can in a row! Make sure to pay attention! If you miss, it's game over!<br/>
        <b><u>Classic:</u></b>
          You get 3 misses to hit as many duckz as you can. This is a great mode to get used to the Hunty Duckz experience. Explore the scenes and make your shots count! Gameplay perfect for switching up the headset to hunt with friends.<br/>
        <b><u>Arcade:</u></b>
          It is Hunty Duckz chaos! Hit as many duckz as you can in a 45 second time frame. Go for hit streaks and in game bonuses to increase your time and grow your score. Be sure to pay attention! Duckz come from everywhere! It's mayhem!<br/>
      </div>

      <footer className="App-footer"> © 2024 Inner Hero LLC. All rights reserved. </footer>

    </div>
  );
}

export default App;
